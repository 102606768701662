import axios from '@/http/axios'

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getReports  (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/advance${queries}`,
    method: 'get'
  })
}

export function getReportDetail  (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/advance/${id}${queries}`,
    method: 'get'
  })
}

export function printReportDetail  (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  queries = ['export=pdf', ...queries]

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/advance/${id}${queries}`,
    method: 'get'
  })
}

export function getActivitiesLog  (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/activity-log${queries}`,
    method: 'get'
  })
}

export function getUsersTotalSales  (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/advance/users-total-sales${queries}`,
    method: 'get'
  })
}

export function getSaleDetailReport  (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/sale-details${queries}`,
    method: 'get'
  })
}

export function printSaleDetailReport ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/sale-details?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getSaleAddonDetailReport  (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/sale-addon-details${queries}`,
    method: 'get'
  })
}

export function printSaleAddonDetailReport ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/sale-addon-details?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function insertReport (payment) {
  return axios({
    url: 'v1/admin/reports/advance',
    data: payment,
    method: 'post'
  })
}

export function getTimeDetailsChart ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/charts/sale-details${queries}`,
    method: 'get',
  })
}

export function getSaleAddonDetailsChart ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/charts/sale-addon-details${queries}`,
    method: 'get',
  })
}

export function getReceivesDetailsChart ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/charts/receives-details${queries}`,
    method: 'get',
  })
}

export function getTopCustomers ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/tops/customers${queries}`,
    method: 'get',
  })
}

export function getTopCourts ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/tops/courts${queries}`,
    method: 'get',
  })
}

export function getTopTimes ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/reports/tops/times${queries}`,
    method: 'get',
  })
}

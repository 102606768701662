<template>
  <div class="courts-list fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="saleAddonDetailReportTable"
                             :columns="columnsLabel"
                             :options="options"
                             :data="data"
                             :showFilterBtn="false"
                             :filters="filtersList"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="handleSelectReport"
                             @load:more="getSaleAddonDetailReport()"/>

    <button id="printTableSaleAddonDetail" v-show="false" @click="printSaleAddonDetailReport"></button>
  </div>
</template>

<script>
  import axios from 'axios'
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import {
    checkUserPermissions,
    getAvatarUrl,
    getTimeFromServer,
    hideLoading,
    showLoading
  } from '../../../../assets/js/functions'
  import CustomIcon from '../../../../components/customIcon/customIcon'
  import {
    getSaleAddonDetailReport,
    printSaleAddonDetailReport,
    printSaleDetailReport
  } from "../../../../http/requests/reports";
  import {getCourts} from "../../../../http/requests/courts";
  import {getSaleAddons} from "../../../../http/requests/salesAddons";
  import {getTerms} from "../../../../http/requests/terms";
  import {getTimes} from "../../../../http/requests/times";

  export default {
    name: 'saleAddonDetailsReport',
    metaInfo() {
      return {
        title: this.$t('report.saleAddonDetail.title')
      }
    },
    components: {
      CustomIcon,
      DraggableDynamicTable
    },
    data () {
      return {
        requestSent: false,
        showUserInvoicesPromptStatus: false,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'totalPrice',
            i18n: 'report.saleAddonDetail.table.header.totalPrice',
            width: 'calc(100% / 6)',
            minWidth: 150,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n: 'draggableTable.filter.types.greaterThanEquals',
              id: 4
            }
            ],
          },
          {
            field: 'quantity',
            i18n: 'report.saleAddonDetail.table.header.quantity',
            width: 'calc(100% / 6)',
            minWidth: 150,
            sortable: true,
            // locked: true,
            filter: true,
            filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n: 'draggableTable.filter.types.greaterThanEquals',
              id: 4
            }
            ],
          },
          {
            field: 'court',
            i18n: 'report.saleAddonDetail.table.header.court',
            width: 'calc(100% / 6 * 2)',
            minWidth: 150,
            sortable: true,
            // locked: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('report.saleAddonDetail.labels.all'),
                value: 0
              }
            ]
          },
          {
            field: 'row',
            i18n: 'report.saleAddonDetail.table.header.row',
            width: '70px',
            minWidth: 70,
            align: 'center',
            // locked: true,
            // sortable: true,
            footer: {
              /*type: 'auto-counter'*/
            }
          }
        ],
        data: [],
        filters: [],
        filtersList: [],
        selectedCourt: this.$t('report.saleAddonDetail.labels.all'),
        sorts: ['order[0]=total_price,desc'],
        selectedReport: {},
        page: 1,
        endedList: false,
        getFiltersStatus: true,
        activeFiltersCount: 0,
        actions: [
          {
            toolbar: [
              {
                type: 'date',
                range: true,
                // permission: 'court.create'
              }
            ],
            leftToolbar: [
              // {
              //   id: 'printTableSaleAddonDetail',
              //   // i18n: 'draggableDynamicTable.actions.print',
              //   icon: 'icon-printer',
              //   iconPack: 'feather'
              // },
              {
                id: 'toggleActiveFilter',
                icon: 'FILTER',
                // height: '27px',
                // width: '27px',
                iconPack: 'useral'
              }
            ]
          }
        ]
      }
    },
    created () {

      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      this.getCourts()
      this.getActiveFilters()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getSaleAddonDetailReport () {
        if (!this.requestSent) {
          this.requestSent = true

          clearTimeout(this.loadingTimer)
          this.loadingTimer = setTimeout(() => {
            if (!this.endedList) {
              if (this.$refs.saleAddonDetailReportTable && this.data.length === 0) {
                this.$refs.saleAddonDetailReportTable.loadMoreStatus = 'FirstLoad'
              } else if (this.$refs.saleAddonDetailReportTable && this.data.length > 0) {
                this.$refs.saleAddonDetailReportTable.loadMoreStatus = 'Loading'
              }

              getSaleAddonDetailReport(this.page, this.filters, this.sorts).then((res) => {
                this.endedList = res.data.data.length === 0
                const reports = res.data.data
                reports.forEach((report) => {

                  this.data.push({
                    row: this.data.length + 1,
                    id: report.id,
                    court: report.court.name,
                    quantity: report.quantity,
                    totalPrice: {
                      type: 'price',
                      value: report.total_price || '0'
                    },
                  })
                })

                if (this.data.length === 0) {
                  this.$vs.notify({
                    title: this.$t('alert.message.title'),
                    text: this.$t('report.saleAddonDetail.notifications.noData'),
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 5000,
                    color: 'warning'
                  })
                  this.handleClick('selectRangeDate')
                }

                this.page = res.data.pagination.current_page + 1
                if (res.data.pagination.current_page === 1) {
                  const row_index = this.columnsLabel.map((e) => {
                    return e.field
                  }).indexOf('row')
                  this.columnsLabel[row_index].footer.value = res.data.pagination.total
                }

                if (this.$refs.saleAddonDetailReportTable) this.$refs.saleAddonDetailReportTable.loadMoreStatus = ''

                this.requestSent = false
              })
                .catch((error) => {
                  if (this.$refs.saleAddonDetailReportTable) this.$refs.saleAddonDetailReportTable.loadMoreStatus = 'Danger'

                  this.requestSent = false
                })
            } else {

              this.requestSent = false
            }
          }, 400)
        }
      },
      setFilters (filters) {
        if (!this.getFiltersStatus) {
          const filters_list = []
          Object.keys(filters).forEach((key) => {
            switch (key) {

              case 'addon':
                let addonActives = []
                let activeAddonName = []
                filters[key].search.forEach((item) => {
                  if (item.show) {
                    addonActives.push(item.value)
                    activeAddonName.push(item.label)
                  }
                })
                if (activeAddonName.length > 0 && activeAddonName.length < filters[key].search.length) {
                  this.$store.dispatch('setPageTitle', this.$t('report.saleAddonDetail.dynamicTitle', {name: activeAddonName.join(this.$t('report.saleAddonDetail.labels.and'))}))
                } else {
                  this.$store.dispatch('setPageTitle', this.$t('report.saleAddonDetail.title'))
                }
                if (addonActives.length > 0) filters_list.push(`sale_addon=${addonActives.join('_')}`)
                break

              case 'court':
                if (filters[key].search.value > 0) filters_list.push(`reserved_court=${filters[key].search.value}`)
                this.selectedCourt = filters[key].search.label
                break

              case 'term':
                let termActives = []
                filters[key].search.forEach((item) => {
                  if (item.show) {
                    termActives.push(item.value)
                  }
                })
                if (termActives.length > 0) filters_list.push(`reserved_term=${termActives.join('_')}`)
                break

              case 'time':
                let timeActives = []
                filters[key].search.forEach((item) => {
                  if (item.show) {
                    timeActives.push(item.value)
                  }
                })
                if (timeActives.length > 0) filters_list.push(`reserved_time=${timeActives.join('_')}`)
                break

              case 'quantity':
                if (filters[key].search !== '') filters_list.push(`reserved_quantity=${filters[key].search},${filters[key].type.id}`)
                break

              case 'totalPrice':
                if (filters[key].search !== '') filters_list.push(`reserved_total_price=${filters[key].search},${filters[key].type.id}`)
                break
            }
          })


          const date = this.$store.state.helper.calendarDate
          if (typeof date === 'object' && date.length > 1) {
            filters_list.push(`reserved_date=${date.join('_')}`)
          } else {
            filters_list.push(`reserved_date=${date}`)
          }

          this.data = []
          this.page = 1
          this.endedList = false
          this.filters = filters_list
          this.getSaleAddonDetailReport()
        }
      },
      setSort (columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {

            case 'row':
              sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'court':
              sorts_list.push(`order[0]=reserved_court,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'quantity':
              sorts_list.push(`order[0]=reserved_quantity,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'totalPrice':
              sorts_list.push(`order[0]=reserved_total_price,${  columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getSaleAddonDetailReport()
      },

      getCourts() {
        getCourts().then(response => {
          const courts = response.data.data
          const court_index = this.columnsLabel.map(e => e.field).indexOf('court')
          if (court_index > -1) {
            courts.forEach((court) => {
              this.columnsLabel[court_index].filterTypes.push({
                value: court.id,
                label: court.name
              })
            })
          }
        })
      },

      getActiveFilters() {
        this.getSaleAddons()
        this.getTerms()
        this.getTimes()
      },

      getTerms() {
        getTerms().then(response => {
          const terms = response.data.data
          let termList = []
          terms.forEach((term) => {
            termList.push({
              id: term.id,
              label: term.name,
              value: term.id,
              show: false
            })
          })

          this.filtersList.push({
            field: `term`,
            name: this.$t('report.saleAddonDetail.filters.term'),
            type: 'checkbox',
            types: termList
          })
          this.activeFiltersCount += 1


          if (this.activeFiltersCount === 3) {
            this.getFiltersStatus = false
          }
        })
          .catch(() => {
            this.activeFiltersCount += 1
          })
      },

      getTimes() {
        getTimes().then(response => {
          const times = response.data.data

          let timeList = []

          times.forEach((time) => {
            timeList.push({
              id: time.id,
              label: `${time.start_time.split(':')[0]} تا ${time.end_time.split(':')[0]}`,
              value: time.id,
              show: false
            })
          })

          this.filtersList.push({
            field: `time`,
            name: this.$t('report.saleAddonDetail.filters.time'),
            type: 'checkbox',
            types: timeList
          })
          this.activeFiltersCount += 1


          if (this.activeFiltersCount === 3) {
            this.getFiltersStatus = false
          }
        })
          .catch(() => {
            this.activeFiltersCount += 1
          })
      },

      getSaleAddons() {
        getSaleAddons().then(response => {
          const addons = response.data.data

          let addonsList = []

          addons.forEach((addon) => {
            addonsList.push({
              id: addon.id,
              label: addon.name,
              value: addon.id,
              show: false
            })
          })

          this.filtersList.push({
            field: `addon`,
            name: this.$t('report.saleAddonDetail.filters.addon'),
            type: 'checkbox',
            types: addonsList
          })
          this.activeFiltersCount += 1


          if (this.activeFiltersCount === 3) {
            this.getFiltersStatus = false
          }
        })
          .catch(() => {
            this.activeFiltersCount += 1
          })
      },

      printSaleAddonDetailReport () {
        showLoading()

        printSaleAddonDetailReport(this.filters, this.sorts).then(response => {
          hideLoading()

          const data = response.data

          let time = getTimeFromServer()
          let fileURL = window.URL.createObjectURL(new Blob([data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `saleAddonDetailReportList-${ time }.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
          .catch((error) => {
            hideLoading()

            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('report.saleAddonDetail.notifications.print.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
      },
      handleSelectReport (row) {
        // this.selectedReport = row
        // this.showUserInvoicesPromptStatus = true
      },
      consoleLog () {
        // console.log(event)
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    },
    watch: {
      '$store.state.helper.calendarDate': {
        handler (val) {
          this.data = []
          this.page = 1
          this.endedList = false

          let reserveFilterIndex = -1
          this.filters.forEach((filter) => {
            if (filter.startsWith('reserved_date=')) {
              reserveFilterIndex = this.filters.indexOf(filter)
            }
          })
          this.filters.splice(reserveFilterIndex, 1)
          if (typeof val === 'object' && val.length > 1) {
            this.filters.push(`reserved_date=${val.join('_')}`)
          } else {
            this.filters.push(`reserved_date=${val}`)
          }
          if (this.activeFiltersCount > 0) {
            this.getSaleAddonDetailReport()
          }
        }
      }
    }
  }
</script>

<style lang="scss">
</style>
